@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 100%;
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
	background-color: hsl(0, 0%, 93%);
}

.main-bg {
  background-color: hsl(0, 0%, 93%);
}

html, body, #root {
	height: 100%;
}


.blur-image {
	width: 100%;
	height: 100vh;
	backdrop-filter: blur(15px) opacity(100%) brightness(75%);
	-webkit-backdrop-filter: blur(15px) opacity(100%) brightness(75%);
	background-color: rgba(255, 255, 255, .15);
	background-size: cover;
	background-repeat: no-repeat;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.frosted-card {
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background-color: rgba(213, 222, 226, 0.3);
	background-size: cover;
	background-repeat: no-repeat;
}

.bottom-frosted-card {
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background-color: rgba(213, 222, 226, 0.75);
	background-size: cover;
	background-repeat: no-repeat;
}

/* CSS for typing animation */
.word {
  display: block;
}

.word span {
  color: white;
  position: relative;
	font-size:40px;
}

.word span::after {
  content: '';
  width: 3px;
  height: 100%;
  background: black;
  display: block;
  position: absolute;
  right: -5px;
  top: 0;
  animation: blink 0.5s ease infinite alternate-reverse;
}

@keyframes blink {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.word.empty {
  visibility: hidden;
}

.word.empty span::after {
  visibility: visible;
  right: 0;
}

:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}